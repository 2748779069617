//
// Custom Buttons Effects
// --------------------------------------------------

//Anis
.btn-anis-effect {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: transparent;
  transition: .2s;
  transition-delay: .1s;
  &:hover{
    transition-delay: .45s;
  }
  &:before, &:after {
    position: absolute;
    top: 50%;
    content: '';
    width: 20px;
    height: 20px;
    background: $gray-800;
    border-radius: 50%;
    transition: 0.5s;
    z-index: -1;
  }

  &:before {
    left: -20px;
    transform: translate(-50%, -50%);
  }

  &:after {
    right: -20px;
    transform: translate(50%, -50%);
  }

  &:hover {
    &:before {
      -webkit-animation: criss-cross-left 0.8s;
      animation: criss-cross-left 0.8s;
    }

    &:after {
      -webkit-animation: criss-cross-right 0.8s;
      animation: criss-cross-right 0.8s;
    }
  }
  &.btn-primary{
    color: $white;
    background-color: $primary;
    &:before, &:after {
      background: $info;
    }
    &:hover{
      color: $white;
      border-color: $info;
      background: $info;
    }
  }
  &.btn-default{
    color: $gray-800;
    background-color: transparent;
    border-color: $gray-500;
    &:hover{
      color: $white;
      border-color: $gray-800;
      background: $gray-800;
    }
  }
  &.btn-deluge{
    color: $deluge;
    &:before, &:after {
      background: $deluge;
    }
    &:hover{
      color: $white;
      border-color: $deluge;
      background: $deluge;
    }
  }
  &.btn-success{
    color: $success;
    &:before, &:after {
      background: $success;
    }
    &:hover{
      color: $white;
      border-color: $success;
      background: $success;
    }
  }
  &.btn-warning{
    color: $warning;
    &:before, &:after {
      background: $warning;
    }
    &:hover{
      color: $white;
      border-color: $warning;
      background: $warning;
    }
  }
  &.btn-danger{
    color: $danger;
    &:before, &:after {
      background: $danger;
    }
    &:hover{
      color: $white;
      border-color: $danger;
      background: $danger;
    }
  }
  &.btn-info{
    color: $info;
    &:before, &:after {
      background: $info;
    }
    &:hover{
      color: $white;
      border-color: $info;
      background: $info;
    }
  }
}


//Aqil
.btn.btn-aqil-effect {
  position: relative;
  overflow: hidden;
  transition: 0.5s;

  border-color: $gray-800;
  color: $gray-800;
  background-color: transparent;

  &:hover{
    background-color: transparent;
    color: $white;
    box-shadow: inset 1px 0 0 0  $gray-800;
  }

  &:before,
  &:after,
  span:before,
  span:after {
    content: '';
    background: rgba(68, 68, 68, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    z-index: -1;
  }

  span:before,
  span:after {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }

  &-mod-1 {
    &:before,
    &:after,
    span:before,
    span:after {
      width: 100%;
      height: 0;
    }

    &:after,
    span:before {
      top: auto;
      bottom: 0;
    }

    &:hover {
      span:before,
      span:after,
      &:before,
      &:after {
        height: 100%;
      }
    }
  }

  &-mod-2 {
    &:before,
    &:after,
    span:before,
    span:after {
      width: 0;
      height: 100%;
    }

    &:after,
    & span:before {
      left: auto;
      right: 0;
    }

    &:hover {
      &:before,
      &:after,
      span:before,
      span:after {
        width: 100%;
      }
    }
  }

  &.btn-primary{
    color: $primary;
    border-color: $primary;
    transition-delay: .4s;

    &:hover{
      color: $white;
      transition-delay: .1s;
      box-shadow: inset 1px 0 0 0 $primary;
    }

    &:before,
    &:after,
    span:before,
    span:after {
      background: rgba($primary, 0.5);
    }
  }
  &.btn-deluge{
    color: $deluge;
    border-color: $deluge;
    transition-delay: .4s;

    &:hover{
      color: $white;
      transition-delay: .1s;
      box-shadow: inset 1px 0 0 0 $deluge;
    }

    &:before,
    &:after,
    span:before,
    span:after {
      background: rgba($deluge, 0.5);
    }
  }
  &.btn-success{
    color: $success;
    border-color: $success;
    transition-delay: .4s;

    &:hover{
      color: $white;
      transition-delay: .1s;
      box-shadow: inset 1px 0 0 0 $success;
    }

    &:before,
    &:after,
    span:before,
    span:after {
      background: rgba($success, 0.5);
    }
  }
  &.btn-warning{
    color: $warning;
    border-color: $warning;
    transition-delay: .4s;

    &:hover{
      color: $white;
      transition-delay: .1s;
      box-shadow: inset 1px 0 0 0 $warning;
    }

    &:before,
    &:after,
    span:before,
    span:after {
      background: rgba($warning, 0.5);
    }
  }
  &.btn-info{
    color: $info;
    border-color: $info;
    transition-delay: .4s;

    &:hover{
      color: $white;
      transition-delay: .1s;
      box-shadow: inset 1px 0 0 0 $info;
    }

    &:before,
    &:after,
    span:before,
    span:after {
      background: rgba($info, 0.5);
    }
  }
  &.btn-danger{
    color: $danger;
    border-color: $danger;
    transition-delay: .4s;

    &:hover{
      color: $white;
      transition-delay: .1s;
      box-shadow: inset 1px 0 0 0 $danger;
    }

    &:before,
    &:after,
    span:before,
    span:after {
      background: rgba($danger, 0.5);
    }
  }
}

//Rayen
.btn.btn-rayen-effect {
  border-radius: 0;
  overflow: hidden;
  background-color: transparent;

  &:before {
    content: attr(data-text);
    position: absolute;
    top: -2px;
    left: 0;
    bottom: -2px;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-100%);
  }
  & > span {
    display: block;
    z-index: -1;
  }
  &:hover {
    background-color: transparent;
    color: $white;
  }
  &:before,
  & > span {
    transition: transform 0.3s;
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  }
  &:hover:before {
    transform: translateY(0);
  }
  &:hover > span {
    transform: translateY(100%);
  }

  &.btn-default{
    color: $gray-800;
    border-color: $gray-800;
    &:hover {
      color: $white;
      box-shadow: inset 1px 0 0 0  $gray-800;
    }
    &:before {
      background-color: $gray-800;
      color: $white;
    }
  }
  &.btn-primary{
    color: $primary;
    border-color: $primary;

    &:hover{
      color: $white;
      box-shadow: inset 1px 0 0 0 $primary;
    }
    &:before {
      background-color: $primary;
    }
  }
  &.btn-deluge{
    color: $deluge;
    border-color: $deluge;

    &:hover{
      color: $white;
      box-shadow: inset 1px 0 0 0 $deluge;
    }
    &:before {
      background-color: $deluge;
    }
  }
  &.btn-success{
    color: $success;
    border-color: $success;

    &:hover{
      color: $white;
      box-shadow: inset 1px 0 0 0 $success;
    }
    &:before {
      background-color: $success;
    }
  }
  &.btn-warning{
    color: $warning;
    border-color: $warning;

    &:hover{
      color: $white;
      box-shadow: inset 1px 0 0 0 $warning;
    }
    &:before {
      background-color: $warning;
    }
  }
  &.btn-info{
    color: $info;
    border-color: $info;

    &:hover{
      color: $white;
      box-shadow: inset 1px 0 0 0 $info;
    }
    &:before {
      background-color: $info;
    }
  }
  &.btn-danger{
    color: $danger;
    border-color: $danger;

    &:hover{
      color: $white;
      box-shadow: inset 1px 0 0 0 $danger;
    }
    &:before {
      background-color: $danger;
    }
  }
}

.lt-ie10 {
  .btn-rayen-effect {
    &::before {
      display: none;
    }
    &:hover {
      color: #20325d;
      background-color: $white;
      > span {
        transform: translateY(0%);
      }
    }
  }
}

// Btn nuka
.btn.btn-nuka-effect {
  transition: color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  border: none;
  color: $white;
  background-color: transparent;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: inherit;
    background: #453866;
    z-index: -1;
  }
  &::before {
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    opacity: 0.2;
    transform: scale3d(0.7, 1, 1);
    transition: transform 0.3s, opacity 0.3s;
  }
  &::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale3d(1.1, 1, 1);
    transition: transform 0.3s, background-color 0.3s;
  }
  &::before,
  &::after {
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
  &:hover {
    background-color: transparent;
    color: #453866;
  }
  &:hover::before {
    opacity: 1;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
  }
  &:hover::after {
    background-color: $white;
  }
  &:hover::after,
  &:hover::before {
    transform: scale3d(1, 1, 1);
  }

  &.btn-default{
    &::before,
    &::after {
      background-color: $gray-800;
    }
    &:hover{
      &:after{
        background-color: $white;
      }
    }
  }
  &.btn-primary{
    &::before,
    &::after {
      background-color: $primary;
    }
    &:hover{
      color: darken($primary, 10%);
      &:after{
        background-color: $white;
      }
    }
  }
  &.btn-deluge{
    &::before,
    &::after {
      background-color: $deluge;
    }
    &:hover{
      color: darken($deluge, 10%);
      &:after{
        background-color: $white;
      }
    }
  }
  &.btn-success{
    &::before,
    &::after {
      background-color: $success;
    }
    &:hover{
      color: darken($success, 10%);
      &:after{
        background-color: $white;
      }
    }
  }
  &.btn-warning{
    &::before,
    &::after {
      background-color: $warning;
    }
    &:hover{
      color: darken($warning, 10%);
      &:after{
        background-color: $white;
      }
    }
  }
  &.btn-info{
    &::before,
    &::after {
      background-color: $info;
    }
    &:hover{
      color: darken($info, 10%);
      &:after{
        background-color: $white;
      }
    }
  }
  &.btn-danger{
    &::before,
    &::after {
      background-color: $danger;
    }
    &:hover{
      color: darken($danger, 10%);
      &:after{
        background-color: $white;
      }
    }
  }
}

.btn.btn-naira-effect {
  overflow: hidden;
  transition: background-color 0.3s, box-shadow 0.2s;

  &::before {
    content: '';
    position: absolute;
    left: -50%;
    width: 200%;
    height: 200%;
    top: -50%;
    transform: translateY(-100%) rotate3d(0, 0, 1, -10deg);
    background: $white;
    transition: .3s;
  }


  &.btn-naira-effect-up::before {
    transform: translateY(100%) rotate3d(0, 0, 1, 10deg);
  }
  .btn-text {
    display: block;
    margin: 0;
  }
  .btn-text,
  .icon {
    transition: 0.3s;
  }
  .icon {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -200%);
    pointer-events: none;
    color: #444;
  }
  .icon-xs{
    font-size: 24px;
  }
  &.btn-naira-effect-up .icon {
    transform: translate(-50%, 300%);
  }

  &:hover {
    box-shadow: inset 0 0 0 2px $white;

    .icon {
      transform: translate(-50%, 50%);
    }
    .btn-text {
      opacity: 0;
      transform: translateY(200%);
    }
    &::before {
      animation: anim-naira-1 0.3s forwards ease-in;
    }
  }

  &.btn-naira-effect-up:hover {
    &::before {
      animation: anim-naira-2 0.3s forwards ease-in;
    }
    .btn-text {
      transform: translateY(-200%);
    }
  }
  &.btn-primary{
    &:hover{
      background-color: transparent;
      border-color: $primary;
    }
    .icon{
      color: darken($primary, 7%);
    }
  }
  &.btn-deluge{
    &:hover{
      background-color: transparent;
      border-color: $deluge;
    }
    .icon{
      color: darken($deluge, 7%);
    }
  }
  &.btn-success{
    &:hover{
      background-color: transparent;
      border-color: $success;
    }
    .icon{
      color: darken($success, 7%);
    }
  }
  &.btn-warning{
    &:hover{
      background-color: transparent;
      border-color: $warning;
    }
    .icon{
      color: darken($warning, 7%);
    }
  }
  &.btn-info{
    &:hover{
      background-color: transparent;
      border-color: $info;
    }
    .icon{
      color: darken($info, 7%);
    }
  }
  &.btn-danger{
    &:hover{
      background-color: transparent;
      border-color: $danger;
    }
    .icon{
      color: darken($danger, 7%);
    }
  }
}
.tablet{
  .btn-naira-effect {
    &:hover{
      background-color: $white;
    }
  }
}

.lt-ie10 {
  .btn-naira-effect {
    &:before {
      display: none;
    }
    &:hover {
      background-color: $white;
    }
  }
}


.btn.btn-polat-effect {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;

  .btn-text {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #fff;
    transition: all 0.3s;
    backface-visibility: hidden;
    margin-top: 0;
  }

  .icon {
    position: absolute;
    height: 100%;
    width: 100%;
    font-size: 180%;
    transition: all 0.3s;
    &:before{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-up {
    .icon {
      left: 0;
      top: -100%;
    }

    &:hover {
      .icon {
        top: 0;
      }

      .btn-text {
        @include transform (translateY(300%));
      }
    }
  }

  &-left {
    .icon {
      left: -100%;
      top: 0;
    }

    &:hover {
      .icon {
        left: 0;
      }

      .btn-text {
        @include transform (translateX(200%));
      }
    }
  }
  &.btn-default{
    .btn-text{
      color: $gray-800;
    }
  }
}

//btn-santos-svg
.btn.btn-santos-svg-effect {
  border: none;
  position: relative;
  transition: color 0.9s;
  background-color: transparent;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &:active,
    &:focus{
      outline: none;
    }
  }

  .shape {
    stroke-width: 6px;
    fill: transparent;
    stroke: $gray-800;
    stroke-dasharray: 50 400;
    stroke-dashoffset: -233;
    transition: 1s all ease;
    width: 100%;
    height: 100%;
  }

  &:active,
  &:focus{
    background-color: transparent;
  }

  &:hover {
    background-color: transparent;

    .shape {
      stroke-dasharray: 50 0;
      stroke-width: 3px;
      stroke-dashoffset: 0;
      stroke: #009FFD;
    }
  }
  &.btn-delault{
    color: $gray-800;
    .shape{
      stroke: darken($gray-800, 17%);
    }
    &:hover{
      color: $gray-800;
      .shape{
        stroke: $gray-800;
      }
    }
  }
  &.btn-primary{
    color: $primary;
    .shape{
      stroke: darken($primary, 17%);
    }
    &:hover{
      color: $primary;
      .shape{
        stroke: $primary;
      }
    }
  }
  &.btn-deluge{
    color: $deluge;
    .shape{
      stroke: darken($deluge, 17%);
    }
    &:hover {
      color: $deluge;
      .shape{
        stroke: $deluge;
      }
    }
  }
  &.btn-success{
    color: $success;
    .shape{
      stroke: darken($success, 17%);
    }
    &:hover {
      color: $success;
      .shape{
        stroke: $success;
      }
    }
  }
  &.btn-warning{
    color: $warning;
    .shape{
      stroke: darken($warning, 17%);
    }
    &:hover {
      color: $warning;
      .shape{
        stroke: $warning;
      }
    }
  }
  &.btn-info{
    color: $info;
    .shape{
      stroke: darken($info, 17%);
    }
    &:hover {
      color: $info;
      .shape{
        stroke: $info;
      }
    }
  }
  &.btn-danger{
    color: $danger;
    .shape{
      stroke: darken($danger, 17%);
    }
    &:hover {
      color: $danger;
      .shape{
        stroke: $danger;
      }
    }
  }
}

html.ie-edge,
html.ie-11,
html.lt-ie11 {
  .btn-santos-svg {
    transition: 0.4s;
    padding: 10px 15px;
    border: 2px solid transparent;

    &:hover{
      border-color: #009FFD;
    }
  }
}
