//
// Pull Responsive
// --------------------------------------------------

.pull-base{
  float: none;
}

@each $alias, $resolution in $grid-breakpoints {
  @media (min-width: $resolution) {
    @include make-pull-alignment($alias, $resolution);
  }
}
