//
// Sections
// --------------------------------------------------

.section-relative {
  position: relative;
  z-index: 1;
}

.section-split {
  @include media-breakpoint-up(xl) {
    margin-left: auto;
    margin-right: 0;
    width: 50%;
  }
}

.section-image-aside {
  position: relative;
  &-img {
    position: absolute;
    top: 30px;
    bottom: 30px;
    width: 60vw;
    -webkit-background-size: cover;
    background-size: cover;
  }
}

.section-image-aside-left {
  .section-image-aside-img {
    right: 100%;
  }
}

.section-image-aside-right {
  .section-image-aside-img {
    left: 100%;
  }
}

// Custom section triangles
// --------------------------------------------------

.section-code {
  padding: 20px;
  border-radius: $border-radius-small;
  box-shadow: inset $shadow-area-xxs;
  background: $gray-100;

  @include media-breakpoint-up(md) {
    padding: 30px;
    margin-left: -30px;
    margin-right: -30px;
  }
}

.section-navigation {
  padding: 20px;
  border-radius: $border-radius-small;
  box-shadow: inset $shadow-area-xxs;
  background: $gray-100;

  iframe {
    border: none;
    //    width: 100%;
    //    height: 100%;
  }

  .resizable {

  }

  @include media-breakpoint-up(md) {
    padding: 30px;
    margin-left: -45px;
    margin-right: -45px;
  }
}

// Custom section triangles
// --------------------------------------------------

.section-triangle {
  position: relative;
  svg {
    position: absolute;
    left: 50%;
    width: 60px;
    height: 30px;
    margin-left: -30px;
  }
  &-bottom {
    svg {
      top: 100%;
      margin-top: -1px;
      z-index: 1;
    }
  }
  &-top {
    svg {
      top: 0;
      transform: rotate(180deg);
      z-index: 1;
    }
  }
}

.section-collar {
  position: relative;

  &, &:before, &:after {
    background: $white;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 100%;
    margin-top: -1px;
    height: 30px;
    width: 60%;
    z-index: 1;
  }

  &:before {
    left: -10%;
    margin-left: -15px;
    @include transform(skewX(-45deg))
  }

  &:after {
    right: -10%;
    margin-right: -15px;
    @include transform(skewX(45deg))
  }
}

.slider-menu-position {
  position: relative;
  .rd-navbar-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.mock-up-wrapper {
  position: relative;
}

.mock-up-desktop-wrapper {
  margin-bottom: -80px;
  max-width: 100%;
}

.mock-up-circle {
  position: absolute;
  left: -15%;
  bottom: 65px;
}

.mock-up-mobile-wrapper {
  position: absolute;
  right: -5%;
  bottom: -140px;
  @include media-breakpoint-up(lg) {
    right: -15%;
  }
}

// One Page Section
// --------------------------------------------------

.one-page {
  position: relative;

  // One Page header and footer
  &-header, &-footer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &-header {
    top: 33px;
  }

  &-footer {
    bottom: 33px;
  }
}

// Full Screen Section
// --------------------------------------------------

.section-cover {
  min-height: 100vh;
  
  .ie-11 & {
    height: 100vh;
  }
}

.section-center-absolute {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// Graph demonstration section
// --------------------------------------------------
.section-graph-demonstration {
  overflow: auto;
  > .graph-content { min-width: 500px; }

  @include media-breakpoint-up(sm) {
    > .graph-content { min-width: 0; }
  }
  .isotope{
    .row {
      > [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  // Fix "Filterable Column Chart" in chrome
  #high-line {
    overflow: hidden;

    svg {
      margin-top: -2px;
    }
  }
}


.section-team-custom-effects {
  position: relative;
  .bg-title {
    font-size: 170px;
    line-height: 204px;
    color: $gray-200;
    position: absolute;
    bottom: 18%;
    left: 46%;
    z-index: -2;
    span {
      display: block;
      position: relative;
      left: 55px;
    }
  }
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .slide-content-animate {
    padding-left: 15px;
    padding-right: 15px;
  }

  .owl-custom-navigation {
    @include media-breakpoint-up(xl) {
      position: absolute;
      bottom: 66px;
      right: 84px;
      z-index: 1;
      width: 41.66667%;
    }
  }
}


// section navbar custom demonstration
.rd-navbar-iframe-demonstration {
  box-shadow: inset $shadow-area-xxs;
  border: 1px solid $gray-300;
  iframe {
    width: 100%;
    border: none;
    outline: 1px solid transparent;
    transition: 0.35s height ease;
    html, body, .page {
      height: 100%;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 0;
    iframe {
      height: 56px;
      &.active,
      &.active.active-panel {
        height: 570px;
      }
      &.active-panel {
        height: 220px;
      }
    }
  }
}

// Section canvas bg animation
.tablet,
.mobile{
  #demo-canvas {
    display: none;
  }
}

#particles-container,
#demo-canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

// Mock up
.mock-up-1 {
  position: relative;
  width: 100%;
  padding-top: 47%;
  
  img {
    max-width: 100%;
    height: auto;
  }

  .scene {
    position: absolute;
    top: -20px;
    bottom: 0;
    left: -20px;
    right: -20px;
    overflow: hidden;
  }

  .object {
    position: absolute;
  }

  .object-1 {
    top: 20px;
    left: calc(28.25% + 20px);
    width: 42%;
  }

  .object-2 {
    top: calc(27% + 20px);
    left: 20px;
    width: 39%;
  }

  .object-3 {
    top: calc(52% + 20px);
    left: calc(22% + 20px);
    width: 54%;
  }

  .object-4 {
    top: calc(36% + 20px);
    right: 20px;
    width: 40%;
  }
}

.mock-up-2 {
  position: relative;
  width: 100%;

  img {
    max-width: 100%;
    height: auto;
  }

  .scene {
    overflow: hidden;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 33.5%;

    .scene {
      position: absolute;
      top: -20px;
      bottom: 0;
      left: -20px;
      right: -20px;
    }

    .object {
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
    }
  }
}

.mock-up-3 {
  position: relative;
  width: 100%;
  padding-top: 44%;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }

  .object {
    position: absolute;
  }

  .object-1 {
    top: 22%;
    left: 4%;
    width: 50%;
  }

  .object-2 {
    top: 22%;
    right: 4%;
    width: 50%;
  }

  .object-3 {
    top: 6%;
    left: 25%;
    width: 50%;
  }
}

.mock-up-4 {
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 58.6%;

    .object {
      position: absolute;
      top: 0;
      left: 0;
      right: 5%;
    }
  }
}

// Section Spacing
// --------------------------------------------------

$insets: (0, 20px, 34px, 41px, 50px, 66px, 85px, 98px, 110px, 124px, 254px, 350px);

@include indent-responsive(section, '', (padding-top, padding-bottom), $grid-breakpoints, $insets);
@include indent-responsive(section, top, padding-top, $grid-breakpoints, $insets);
@include indent-responsive(section, bottom, padding-bottom, $grid-breakpoints, $insets);
